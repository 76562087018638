import { IconFileTypeXml, IconHome, IconUserCog, IconMoon, IconSun } from '@tabler/icons-react';
import {useLocation, useNavigate} from "react-router-dom";
import { useState } from 'react';


export const Nav=({toggleDarkMode, isDark}) => {
    const [isMenuOpen, setIsMenuOpen] = useState(false)
    const navigate = useNavigate();
    const location = useLocation();
    const navigateTo = (site) => {
        navigate(site);
    };
    const handleToggle = () => {
        setIsMenuOpen((prev) => !prev);
    };

    return (
        <header className="navbar navbar-expand-md d-print-none">
            <div className="container-xl">
                <button className="navbar-toggler" type="button" onClick={handleToggle}>
                    <span className="navbar-toggler-icon"></span>
                </button>
                <div className="navbar-brand navbar-brand-autodark d-none-navbar-horizontal pe-0 pe-md-3">
                    <a onClick={() => navigateTo('/')}>
                        <img src={isDark ? "/img/logo-white.svg": "/img/logo.svg"} alt="Toolify Logo" style={{ height: '50px' }} />
                    </a>
                </div>
                <div className={`navbar-collapse ${isMenuOpen ? 'show' : 'collapse'}`}>
                <div className="d-flex flex-column flex-md-row flex-fill align-items-stretch align-items-md-center justify-between">
                        <ul className="navbar-nav flex-grow-1">
                            <li className="nav-item me-1">
                                <button className="nav-link" onClick={() => navigateTo('/')}>
                                    <IconHome/>
                                    <span className="nav-link-title ms-2">Dashboard</span>
                                </button>
                            </li>
                            <li className="nav-item me-1">
                                <button className={`nav-link ${location.pathname.includes("AppLockerXMLParser") ? "active" : ""}`} onClick={() => navigateTo('/tools/AppLockerXMLParser')}>
                                    <IconFileTypeXml/>
                                    <span className="nav-link-title ms-2"> AppLocker XML Parser </span>
                                </button>
                            </li>
                            <li className="nav-item me-1">
                                <button className={`nav-link ${location.pathname.includes("HybridRecipientManagement") ? "active" : ""}`} onClick={() => navigateTo('/tools/HybridRecipientManagement')}>
                                    <IconUserCog/>
                                    <span className="nav-link-title ms-2"> Hybrid Recipient Management</span>
                                </button>
                            </li>
                        </ul>

                        {/* Dark Mode Toggle Icon (rechts) */}
                        <div className="nav-item ms-auto">
                            <button className="nav-link" onClick={toggleDarkMode} title="Toggle Dark Mode">
                                {isDark ? <IconSun /> : <IconMoon />}
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </header>
    );
}