import { Outlet } from 'react-router-dom';
import { Nav } from "./Nav";

const Layout = ({isDark, toggleDarkMode}) => {

    return (
        <div className="page min-h-screen flex flex-col">
            <Nav isDark={isDark} toggleDarkMode={toggleDarkMode} />
            <div className="page-wrapper flex-grow">
                <Outlet />
            </div>
        </div>
    );
};

export default Layout;