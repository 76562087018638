import './App.css';
import {ToastContainer} from 'react-toastify';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import { AppLockerXMLParser } from "./Pages/AppLockerXMLParser";
import { Dashboard } from "./Pages/Dashboard";
import { HybridRecipientManagement } from "./Pages/HybridRecipientManagement";

import '@tabler/core/dist/css/tabler.min.css';
import Layout from "./Components/Default/Layout";
import {useEffect, useState} from "react";


function App() {
    const [isDark, setIsDark] = useState(() => {
        const savedTheme = localStorage.getItem('tablerTheme');
        return savedTheme === 'dark';  // initial aus localStorage
    });

    useEffect(() => {
        const theme = isDark ? 'dark' : 'light';
        document.body.setAttribute('data-bs-theme', theme);
        localStorage.setItem('tablerTheme', theme);
    }, [isDark]);

    const toggleDarkMode = () => {
        setIsDark((prev) => !prev);
    };

  return (
      <Router>
          <ToastContainer closeOnClick/>
        <Routes>
            <Route path="/" element={<Layout isDark={isDark} toggleDarkMode={toggleDarkMode} />} >
                <Route path="/" element={<Dashboard />} />
                <Route path="/tools/AppLockerXMLParser" element={<AppLockerXMLParser isDark={isDark}/>} />
                <Route path="/tools/HybridRecipientManagement" element={<HybridRecipientManagement isDark={isDark}/>} />
            </Route>
        </Routes>
      </Router>
  );
}

export default App;