import React, { useState, useEffect } from 'react';
import XMLCodeEditor from '../Components/XMLParser/XMLCodeEditor';
import {
    IconFileCode,
    IconPuzzle,
    IconTerminal,
    IconPackage,
    IconApps,
    IconFileText,
} from '@tabler/icons-react';

const sidMap = {
    'S-1-1-0': 'Everyone',
    'S-1-5-32-544': 'Local Administrators',
};

const convertSID = (sid) => {
    return sidMap[sid] ? `${sid} (${sidMap[sid]})` : sid;
};

const getTypeStyle = (type) => {
    const typeLower = type.toLowerCase();
    switch (typeLower) {
        case 'exe':
            return {
                icon: <IconFileCode className="w-5 h-5 text-indigo-500" />,
                badgeClass: 'bg-indigo-100 text-indigo-800',
            };
        case 'dll':
            return {
                icon: <IconPuzzle className="w-5 h-5 text-indigo-500" />,
                badgeClass: 'bg-indigo-100 text-indigo-800',
            };
        case 'script':
            return {
                icon: <IconTerminal className="w-5 h-5 text-green-500" />,
                badgeClass: 'bg-green-100 text-green-800',
            };
        case 'msi':
            return {
                icon: <IconPackage className="w-5 h-5 text-yellow-500" />,
                badgeClass: 'bg-yellow-100 text-yellow-800',
            };
        case 'appx':
            return {
                icon: <IconApps className="w-5 h-5 text-blue-500" />,
                badgeClass: 'bg-blue-100 text-blue-800',
            };
        default:
            return {
                icon: <IconFileText className="w-5 h-5 text-gray-500" />,
                badgeClass: 'bg-gray-100 text-gray-800',
            };
    }
};

const highlightText = (text, query) => {
    if (!query) return text;
    const regex = new RegExp(`(${query})`, 'gi');
    const parts = text.split(regex);
    return parts.map((part, i) =>
        regex.test(part) ? <mark key={i} className="bg-yellow-200">{part}</mark> : part
    );
};

export const AppLockerXMLParser = ({isDark}) => {
    const [xmlInput, setXmlInput] = useState('');
    const [parsedOutput, setParsedOutput] = useState([]);
    const [errors, setErrors] = useState([]);
    const [openCollections, setOpenCollections] = useState([]);
    const [searchQuery, setSearchQuery] = useState('');
    const [searchResultCount, setSearchResultCount] = useState(0);

    const toggleCollection = (idx) => {
        setOpenCollections((prev) => {
            const updated = [...prev];
            updated[idx] = !updated[idx];
            return updated;
        });
    };

    const handleAnalyze = (content) => {
        setXmlInput(content);
        try {
            const parser = new DOMParser();
            const dom = parser.parseFromString(content, 'text/xml');
            const parseErrors = dom.getElementsByTagName('parsererror');

            if (parseErrors.length > 0) {
                const errorMsg = parseErrors[0].textContent;
                setErrors([`❌ Error: Invalid XML structure.\n${errorMsg}`]);
                setParsedOutput([]);
                setSearchResultCount(0);
                return;
            }

            const ruleCollections = dom.getElementsByTagName('RuleCollection');
            if (ruleCollections.length === 0) {
                setErrors(['❌ No valid RuleCollection found in the XML.']);
                setParsedOutput([]);
                setSearchResultCount(0);
                return;
            }

            const formatCondition = (condition, query) => {
                const rows = [];

                const attrs = Array.from(condition.attributes || []);
                attrs.forEach((attr, index) => {
                    rows.push(
                        <tr key={`attr-${index}`}>
                            <td className="w-1">
                                <span className="badge badge-outline text-indigo">{attr.name}</span>
                            </td>
                            <td>{highlightText(attr.value, query)}</td>
                        </tr>
                    );
                });

                if (condition.tagName === 'FileHashCondition') {
                    const fileHash = condition.getElementsByTagName('FileHash')[0];
                    if (fileHash) {
                        const hashAttrs = Array.from(fileHash.attributes || []);
                        hashAttrs.forEach((attr, index) => {
                            rows.push(
                                <tr key={`hash-${index}`}>
                                    <td className="w-1">
                                        <span className="badge badge-outline text-indigo">{attr.name}</span>
                                    </td>
                                    <td>{highlightText(attr.value, query)}</td>
                                </tr>
                            );
                        });
                    }
                }

                const binaryVersionRange = condition.getElementsByTagName('BinaryVersionRange')[0];
                if (binaryVersionRange) {
                    let low = binaryVersionRange.getAttribute('LowSection') || '-';
                    let high = binaryVersionRange.getAttribute('HighSection') || '-';

                    if (low === '*') low = 'any';
                    if (high === '*') high = 'any';

                    rows.push(
                        <tr key="binary-range">
                            <td className="w-1">
                                <span className="badge badge-outline text-indigo">Binary Version Range</span>
                            </td>
                            <td>
                                <span className="badge bg-green-lt text-green me-2">Low</span> {highlightText(low, query)}
                                <span className="ms-3 me-2"></span>
                                <span className="badge bg-red-lt text-red me-2">High</span> {highlightText(high, query)}
                            </td>
                        </tr>
                    );
                }

                return (
                    <table className="table table-vcenter card-table">
                        <tbody>{rows}</tbody>
                    </table>
                );
            };

            let resultCount = 0;

            const result = Array.from(ruleCollections).map((collection, idx) => {
                const type = collection.getAttribute('Type');
                const enforcementMode = collection.getAttribute('EnforcementMode') || 'Not specified';
                const rules = [];
                const { icon, badgeClass } = getTypeStyle(type);

                ['FilePublisherRule', 'FilePathRule', 'FileHashRule'].forEach((tag) => {
                    rules.push(...Array.from(collection.getElementsByTagName(tag)));
                });

                const filteredContent = rules.map((rule, ruleIdx) => {
                    const action = rule.getAttribute('Action');
                    const name = rule.getAttribute('Name') || '';
                    const id = rule.getAttribute('Id') || '';
                    const description = rule.getAttribute('Description') || 'No description provided.';
                    const sid = rule.getAttribute('UserOrGroupSid') || '';

                    const conditions = rule.getElementsByTagName('Conditions')[0];
                    const exceptions = rule.getElementsByTagName('Exceptions')[0];

                    let conditionText = '';
                    if (conditions) {
                        const walker = document.createTreeWalker(conditions, NodeFilter.SHOW_TEXT, null);
                        while (walker.nextNode()) {
                            conditionText += walker.currentNode.nodeValue + ' ';
                        }
                    }
                    if (exceptions) {
                        const walker = document.createTreeWalker(exceptions, NodeFilter.SHOW_TEXT, null);
                        while (walker.nextNode()) {
                            conditionText += walker.currentNode.nodeValue + ' ';
                        }
                    }

                    const combinedText = `${name} ${id} ${description} ${sid} ${conditionText}`.toLowerCase();
                    if (searchQuery && !combinedText.includes(searchQuery.toLowerCase())) {
                        return null;
                    }

                    resultCount++;

                    return (
                        <div key={ruleIdx} className="card cardshadow mb-3">
                            <div className="card-header flex justify-between items-start flex-wrap">
                                <div className="flex flex-col">
                                    <h3 className="card-title">{highlightText(name, searchQuery)}</h3>
                                    <span className="text-sm text-muted mt-1">
                                        <span className="badge badge-outline text-default">ID</span> {highlightText(id, searchQuery)}
                                    </span>
                                    <p className="text-sm text-gray-600 mb-0 mt-1">
                                        <span className="badge badge-outline text-default">Description</span> {highlightText(description, searchQuery)}
                                    </p>
                                    <p className="text-sm text-gray-600 mb-0 mt-1">
                                        <span className="badge badge-outline text-default">SID</span> {highlightText(convertSID(sid), searchQuery)}
                                    </p>
                                </div>
                            </div>

                            <div className={`ribbon ${action === "Allow" ? "bg-green" : "bg-red"}`}>
                                {action}
                            </div>

                            <div className="card-body p-2">
                                <h4 className="font-semibold mb-1">Conditions:</h4>

                                <div className="list-group list-group-flush">
                                    {conditions ? (
                                        Array.from(conditions.children).map((cond, condIdx) => (
                                            <p key={condIdx} className="list-group-item p-1 border-0">
                                                {formatCondition(cond, searchQuery)}
                                            </p>
                                        ))
                                    ) : (
                                        <p>No conditions specified.</p>
                                    )}

                                    {exceptions && (
                                        <>
                                            <h4 className="font-semibold mt-4 mb-1 text-red-500">Exceptions:</h4>
                                            {Array.from(exceptions.children).map((exc, excIdx) => (
                                                <a key={excIdx} className="list-group-item p-1 border-0">
                                                    {formatCondition(exc, searchQuery)}
                                                </a>
                                            ))}
                                        </>
                                    )}
                                </div>
                            </div>
                        </div>
                    );
                }).filter(Boolean);

                if (filteredContent.length === 0) return null;

                return {
                    idx,
                    type,
                    enforcementMode,
                    icon,
                    badgeClass,
                    rules,
                    content: filteredContent,
                };
            }).filter(Boolean);

            setParsedOutput(result);
            setOpenCollections(Array(result.length).fill(false));
            setErrors([]);
            setSearchResultCount(resultCount);
        } catch (err) {
            setErrors([`❌ Unexpected error: ${err.message}`]);
            setParsedOutput([]);
            setSearchResultCount(0);
        }
    };

    useEffect(() => {
        if (xmlInput) {
            handleAnalyze(xmlInput);
        }
    }, [searchQuery]);

    return (
        <>
            <div className="page-header d-print-none">
                <div className="container-xl">
                    <div className="row g-2 align-items-center">
                        <div className="col">
                            <h2 className="page-title">AppLocker XML Parser</h2>
                        </div>
                    </div>
                </div>
            </div>

            <div className="page-body">
                <div className="container-xl">
                    <div className="card">
                        <div className="card-header">
                            <h3 className="card-title">Paste and analyze AppLocker XML configurations...</h3>
                        </div>
                        <div className="card-body">
                            <XMLCodeEditor value={xmlInput} onChange={handleAnalyze} isDark={isDark} className="w-full p-2 border border-gray-400 rounded mb-4 font-mono" />
                            {errors.length > 0 && (
                                <div className="mt-4 text-red-600">
                                    {errors.map((err, idx) => (
                                        <p key={idx} className="mb-2 whitespace-pre-wrap">🛑 {err}</p>
                                    ))}
                                </div>
                            )}

                            {xmlInput && (
                                <>
                                    <div className="mb-4">
                                        <input
                                            type="text"
                                            value={searchQuery}
                                            onChange={(e) => setSearchQuery(e.target.value)}
                                            placeholder="Search rules and conditions..."
                                            className="w-full p-2 border border-gray-400 rounded"
                                        />
                                        <p className="text-sm text-gray-600 mt-2">
                                            {searchQuery ? (
                                                <>🔎 {searchResultCount} matching rule{searchResultCount !== 1 ? 's' : ''} found.</>
                                            ) : (
                                                <>Type to search rules and conditions.</>
                                            )}
                                        </p>
                                    </div>
                                </>
                            )}

                            {parsedOutput.length > 0 ? (
                                parsedOutput.map((collection, idx) => (
                                    <div key={idx} className="card cardshadow mb-6 relative">
                                        <div className={`ribbon ${
                                            collection.enforcementMode.toLowerCase() === 'enforced' ? 'bg-green' :
                                                collection.enforcementMode.toLowerCase().includes('audit') ? 'bg-blue' :
                                                    'bg-gray'
                                        }`}>
                                            {collection.enforcementMode}
                                        </div>

                                        <div
                                            className="card-header flex justify-between items-center flex-nowrap cursor-pointer hover:bg-gray-50 transition"
                                            onClick={() => toggleCollection(idx)}
                                        >
                                            <div className="flex items-center space-x-2">
                                                {collection.icon}
                                                <span className={`badge ${collection.badgeClass} text-sm px-3 py-1 rounded font-semibold`}>
                                                    {collection.type}
                                                </span>
                                                <span className="badge bg-gray-100 text-gray-700 text-xs px-2 py-0.5 rounded">
                                                    {collection.content.length} rule{collection.content.length !== 1 ? 's' : ''}
                                                </span>
                                            </div>
                                        </div>

                                        {openCollections[idx] && (
                                            <div className="ml-4 mt-3 p-3">
                                                {collection.content}
                                            </div>
                                        )}
                                    </div>
                                ))
                            ) : (
                                xmlInput && searchQuery && (
                                    <p className="text-gray-500 italic mt-4">
                                        No matching rules found for "<strong>{searchQuery}</strong>".
                                    </p>
                                )
                            )}
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};