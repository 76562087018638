import { Link } from 'react-router-dom';
import { IconFileTypeXml, IconUserCog } from '@tabler/icons-react';

export function Dashboard() {
    const tools = [
        {
            name: 'AppLocker XML Parser',
            description: 'Upload your XML, filter and search rules by path, publisher, or hash, and get clear insights in seconds.',
            icon: <IconFileTypeXml size={32} stroke={1.5} />,
            path: '/tools/AppLockerXMLParser',
            createdAt: '2025-03-24', // YYYY-MM-DD
        },
        {
            name: 'Hybrid Recipient Management',
            description: 'Manage Exchange hybrid recipients with scenario-based PowerShell commands.',
            icon: <IconUserCog size={32} stroke={1.5} />,
            path: '/tools/HybridRecipientManagement',
            createdAt: '2025-03-24', // YYYY-MM-DD
        },
    ];
    const isNewTool = (createdAt) => {
        if (!createdAt) return false;
        const createdDate = new Date(createdAt);
        const now = new Date();
        const diffTime = now - createdDate;
        const diffDays = diffTime / (1000 * 60 * 60 * 24);
        return diffDays <= 30;
    };

    return (
        <div className="container-xl mt-4">
            <h1 className="mb-4">Dashboard</h1>

            <div className="row row-cards">
                {tools.map((tool, idx) => (
                    <div className="col-sm-6 col-lg-4" key={idx}>
                        <Link to={tool.path} className="card card-link h-100">
                            <div className="card-body d-flex flex-column justify-content-between h-100">
                                <div className="mb-3 text-blue">{tool.icon}</div>
                                <div>
                                    <h3 className="card-title d-flex align-items-center">
                                        {tool.name}
                                        {isNewTool(tool.createdAt) && (
                                            <span className="badge bg-green-lt text-green ms-2">NEW</span>
                                        )}
                                    </h3>
                                    <p className="text-muted">{tool.description}</p>
                                </div>
                            </div>
                        </Link>
                    </div>
                ))}
            </div>
        </div>
    );
}