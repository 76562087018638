import CodeMirror from '@uiw/react-codemirror';
import { xml } from '@codemirror/lang-xml';

const XMLCodeEditor = ({ value, onChange, isDark }) => {
    const editorTheme = isDark ? 'dark' : 'light';

    return (
        <div className="w-full border border-gray-400 rounded mb-4 font-mono">
            <CodeMirror
                value={value}
                height="400px"
                extensions={[xml()]}
                onChange={(val) => onChange(val)}
                theme={editorTheme}
                placeholder="Paste your XML here..."
                basicSetup={{
                    lineNumbers: true,
                    foldGutter: true,
                    highlightActiveLine: true,
                }}
            />
        </div>
    );
};

export default XMLCodeEditor;