import React, { useEffect, useState } from 'react';
import Select from 'react-select';
import {toast} from 'react-toastify';

export const HybridRecipientManagement = ({isDark}) => {
    const [selectedScenario, setSelectedScenario] = useState(null);
    const [inputs, setInputs] = useState({});
    const [routingDomain, setRoutingDomain] = useState('');

    const scenarios = [
        {
            title: 'User Creation',
            description: 'Enable RemoteMailbox for new user.',
            commandTemplate: 'Enable-RemoteMailbox <UserEmail> -RemoteRoutingAddress <RoutingAddress> -Alias <Alias>',
            placeholders: ['<UserEmail>', '<Alias>'],
            placeholdersText: {
                '<UserEmail>': 'e.g., john.doe@toolify.at',
                '<Alias>': 'e.g., john.doe'
            }
        },
        {
            title: 'Mailbox Permissions',
            description: 'Grant mailbox permissions.',
            commandTemplate: 'Add-MailboxPermission -Identity <Mailbox> -User <UserEmail> -AccessRights <AccessRights> -InheritanceType All -AutoMapping $true',
            placeholders: ['<Mailbox>', '<UserEmail>', '<AccessRights>'],
            examples: [
                { placeholder: '<AccessRights>', label: 'Full Access', value: 'FullAccess' },
                { placeholder: '<AccessRights>', label: 'Read Only', value: 'ReadPermission' },
                { placeholder: '<AccessRights>', label: 'Delete Item', value: 'DeleteItem' }
            ],
            placeholdersText: {
                '<Mailbox>': 'e.g., shared.support@toolify.at',
                '<UserEmail>': 'e.g., john.doe@toolify.at',
                '<AccessRights>': 'e.g., FullAccess/Read Only/...'
            }
        },
        {
            title: 'Default Calendar Rights',
            description: 'Set default calendar permissions.',
            commandTemplate: 'Set-MailboxFolderPermission -Identity <Mailbox>:\\<CalendarName> -User Default -AccessRights <AccessRights>',
            placeholders: ['<Mailbox>', '<CalendarName>', '<AccessRights>'],
            examples: [
                { placeholder: '<AccessRights>', label: 'Reviewer (read only)', value: 'Reviewer' },
                { placeholder: '<AccessRights>', label: 'Editor (read/write)', value: 'Editor' },
                { placeholder: '<AccessRights>', label: 'AvailabilityOnly', value: 'AvailabilityOnly' }
            ],
            placeholdersText: {
                '<Mailbox>': 'e.g., room101@toolify.at',
                '<CalendarName>': 'e.g., Kalender/Calendar',
                '<AccessRights>': 'e.g., Reviewer/Editor/...'
            }
        },
        {
            title: 'Change User to Shared',
            description: 'Convert mailbox to shared.',
            commandTemplate: 'Set-RemoteMailbox <UserEmail> -Type Shared',
            placeholders: ['<UserEmail>'],
            placeholdersText: {
                '<UserEmail>': 'e.g., john.doe@toolify.at'
            }
        },
        {
            title: 'Create Shared Mailbox',
            description: 'Enable shared RemoteMailbox.',
            commandTemplate: 'Enable-RemoteMailbox <UserEmail> -RemoteRoutingAddress <RoutingAddress> -Shared -Alias <Alias>',
            placeholders: ['<UserEmail>', '<Alias>'],
            placeholdersText: {
                '<UserEmail>': 'e.g., info@toolify.at',
                '<Alias>': 'e.g., info'
            }
        },
        {
            title: 'Change Alias',
            description: 'Add/remove alias on RemoteMailbox.',
            commandTemplate: 'Set-RemoteMailbox <UserEmail> -EmailAddresses @{add="<AddEmail>"}\nSet-RemoteMailbox <UserEmail> -EmailAddresses @{remove="<RemoveEmail>"}',
            placeholders: ['<UserEmail>', '<AddEmail>', '<RemoveEmail>'],
            placeholdersText: {
                '<UserEmail>': 'e.g., john.doe@toolify.at',
                '<AddEmail>': 'e.g., john.add@toolify.at',
                '<RemoveEmail>': 'e.g., john.remove@toolify.at'
            }
        },
        {
            title: 'New Resource Mailbox',
            description: 'Create resource mailbox (Room/Equipment).',
            commandTemplate: 'New-RemoteMailbox <Mailbox> -RemoteRoutingAddress <RoutingAddress> -<RoomOrEquip>',
            placeholders: ['<Mailbox>', '<RoomOrEquip>'],
            placeholdersText: {
                '<Mailbox>': 'e.g., beamer01@toolify.at',
                '<RoomOrEquip>': 'e.g., Room/Equip'
            }
        },
        {
            title: 'Resource Mailbox Settings',
            description: 'Adjust calendar subject behavior for resource mailboxes.',
            commandTemplate: 'Get-Mailbox | Where-Object {$_.ResourceType -eq "Room" -or $_.ResourceType -eq "Equipment"} | Set-CalendarProcessing -DeleteSubject $False -AddOrganizerToSubject $True',
            placeholders: ['<Mailbox>', '<RoomOrEquip>'],
            placeholdersText: {
                '<Mailbox>': 'e.g., beamer01@toolify.at',
                '<RoomOrEquip>': 'e.g., Room/Equip'
            }
        },
        {
            title: 'User Offboarding',
            description: 'Disable user in AD, convert mailbox to shared, and remove all Office 365 licenses using Microsoft Graph.',
            commandTemplate:
                '### Step 1: Disable user in Active Directory\n' +
                'Disable-ADAccount -Identity <ADUser>    # disables the AD user account\n\n' +
                '### Step 2: Convert mailbox to shared\n' +
                'Set-RemoteMailbox <UserEmail> -Type Shared    # converts the mailbox to shared type\n\n' +
                '### Step 3: Connect to Microsoft Graph\n' +
                'Connect-MgGraph -Scopes "User.ReadWrite.All", "Organization.Read.All"    # connect with required Graph scopes\n\n' +
                '### Step 4: Remove all Office 365 licenses\n' +
                '$UserObject = Get-MgUser -UserId <UserEmail>    # get user object by UPN\n' +
                '$AssignedLicenses = (Get-MgUserLicenseDetail -UserId $UserObject.Id).SkuId    # get all assigned license SkuIds\n' +
                'Set-MgUserLicense -UserId $UserObject.Id -AddLicenses @() -RemoveLicenses $AssignedLicenses    # remove all licenses',
            placeholders: ['<ADUser>', '<UserEmail>'],
            placeholdersText: {
                '<ADUser>': 'e.g., SAM Account Name "john.doe"',
                '<UserEmail>': 'e.g., john.doe@toolify.at'
            }
        },
        {
            title: 'New Mail User',
            description: 'Create a mail-enabled user (no mailbox).',
            commandTemplate: 'New-MailUser -Name "<DisplayName>" -ExternalEmailAddress <ExternalEmail> -UserPrincipalName <UserEmail> -Alias <Alias>',
            placeholders: ['<DisplayName>', '<ExternalEmail>', '<UserEmail>', '<Alias>'],
            placeholdersText: {
                '<DisplayName>': 'e.g., John Doe (ext)',
                '<ExternalEmail>': 'e.g., john@externaldomain.at',
                '<UserEmail>': 'e.g., john.doe@toolify.at',
                '<Alias>': 'e.g., john.doe'
            }
        },
        {
            title: 'Remove Remote Mailbox',
            description: 'Remove a RemoteMailbox object.',
            commandTemplate: 'Remove-RemoteMailbox <UserEmail> -Confirm:$false',
            placeholders: ['<UserEmail>'],
            placeholdersText: {
                '<UserEmail>': 'e.g., john.doe@toolify.at'
            }

        },
        {
            title: 'Manage Distribution Group Members',
            description: 'Add and remove members from a distribution group.',
            commandTemplate:
                'Add-DistributionGroupMember -Identity <Group> -Member <UserEmail>\nRemove-DistributionGroupMember -Identity <Group> -Member <UserEmail>',
            placeholders: ['<Group>', '<UserEmail>'],
            placeholdersText: {
                '<Group>': 'e.g., support@toolify.at',
                '<UserEmail>': 'e.g., john.doe@toolify.at'
            }
        },
        {
            title: 'New Distribution Group',
            description: 'Create a new distribution group.',
            commandTemplate: 'New-DistributionGroup -Name "<GroupName>" -Alias <Alias> -PrimarySmtpAddress <GroupEmail>',
            placeholders: ['<GroupName>', '<Alias>', '<GroupEmail>'],
            placeholdersText: {
                '<GroupName>': 'e.g., Support-Team',
                '<Alias>': 'e.g., support',
                '<GroupEmail>': 'e.g., support@toolify.at'
            }
        },
        {
            title: 'Set Email Address Policy',
            description: 'Modify email address policy settings for Exchange Online.',
            commandTemplate:
                'Set-EmailAddressPolicy -Identity "<PolicyName>" -EnabledEmailAddressTemplates "<SMTPTemplate>" -Priority <Priority> -ConditionalCompany "<Company>" -IncludedRecipients <Recipients>',
            placeholders: ['<PolicyName>', '<SMTPTemplate>', '<Priority>', '<Company>', '<Recipients>'],
            placeholdersText: {
                '<PolicyName>': 'e.g., DefaultPolicy',
                '<SMTPTemplate>': 'e.g., SMTP:@toolify.at',
                '<Priority>': 'e.g., 1',
                '<Company>': 'e.g., Toolify GmbH',
                '<Recipients>': 'e.g., MailboxUsers, MailContacts'
            },
            examples: [
                {
                    placeholder: '<SMTPTemplate>',
                    label: 'Default SMTP for toolify.at',
                    value: 'SMTP:@toolify.at'
                },
                {
                    placeholder: '<Recipients>',
                    label: 'Mailbox users only',
                    value: 'MailboxUsers'
                },
                {
                    placeholder: '<Recipients>',
                    label: 'All recipients',
                    value: 'AllRecipients'
                },
                {
                    placeholder: '<Recipients>',
                    label: 'Mail contacts only',
                    value: 'MailContacts'
                }
            ]
        },
        {
            title: 'Get Email Address Policy',
            description: 'View email address policies and their configuration.',
            commandTemplate: 'Get-EmailAddressPolicy | Format-Table Name, Priority, RecipientFilter, EnabledEmailAddressTemplates',
            placeholders: [],
        },
    ];

    const filteredScenarios = scenarios.map((scenario) => ({
        value: scenario.title,
        description: scenario.description,
        title: scenario.title,
        commandTemplate: scenario.commandTemplate,
        placeholders: scenario.placeholders,
        placeholdersText: scenario.placeholdersText,
        examples: scenario.examples || [],
        label: scenario.title,
    }));

    useEffect(() => {
        console.log(filteredScenarios);
    }, [filteredScenarios]);

    const handleInputChange = (placeholder, value) => {
        setInputs((prevInputs) => ({
            ...prevInputs,
            [placeholder]: value,
        }));
    };

    const getEffectiveRoutingAddress = () => {
        const userEmail = inputs['<UserEmail>'] || '';
        const [prefix, domainPart] = userEmail.split('@');

        let domain = 'toolify365.mail.onmicrosoft.com'; // Default

        if (routingDomain.trim()) {
            domain = routingDomain.trim();
        } else if (domainPart) {
            const tenantBase = domainPart.split('.')[0];
            domain = `${tenantBase}.mail.onmicrosoft.com`;
        }

        const localPart = prefix || 'newuser';
        return `${localPart}@${domain}`;
    };

    const generateCommand = () => {
        let cmd = selectedScenario.commandTemplate;

        const routingAddress = getEffectiveRoutingAddress();
        cmd = cmd.replace(/<RoutingAddress>/g, routingAddress);

        selectedScenario.placeholders.forEach((ph) => {
            const val = inputs[ph] || ph;
            cmd = cmd.replace(new RegExp(ph, 'g'), val);
        });

        return cmd;
    };

    const handleCopy = () => {
        navigator.clipboard.writeText(generateCommand());
        toast.info('Copied!', { theme: isDark ? 'dark' : 'light' });
    };


    return (
        <div className="container-xl mt-4">
            <h2 className="mb-3">Hybrid Recipient Management</h2>

            <div className="card mb-4">
                <div className="card-body">
                    <label className="form-label">Remote Routing Domain:</label>
                    <input
                        type="text"
                        className="form-control mb-2"
                        placeholder="e.g., toolify365.mail.onmicrosoft.com"
                        value={routingDomain}
                        onChange={(e) => setRoutingDomain(e.target.value)}
                    />
                    <small className="form-hint">
                        ℹ️ Default: toolify365.mail.onmicrosoft.com – only domain part required.
                    </small>
                </div>
            </div>

            <div className="card mb-4">
                <div className="card-body">
                    <label className="form-label">Select Scenario:</label>
                    <Select
                        className="form-Select"
                        options={filteredScenarios}
                        value={selectedScenario}
                        placeholder={'Select or type...'}
                        onChange={(option) => setSelectedScenario(option)}

                        theme={
                            isDark ?
                                (theme) => ({
                                    ...theme,                   colors: {
                                        neutral0: '#151f2c',
                                        primary: 'gray',
                                        primary25: 'gray',
                                    }
                                }) : (theme) => ({...theme})}

                    />
                </div>
            </div>
            {selectedScenario && (
                <div className="card">
                    <div className="card-body">
                        <h3 className="card-title">{selectedScenario.title}</h3>
                        <p className="text-muted">{selectedScenario.description}</p>

                        {selectedScenario.placeholders.map((ph, idx) => {
                            const hasExamples = selectedScenario.examples?.some((ex) => ex.placeholder === ph);
                            const exampleOptions = selectedScenario.examples
                                ?.filter((ex) => ex.placeholder === ph)
                                .map((ex) => ({ label: ex.label, value: ex.value }));

                            const currentValue = inputs[ph] || '';

                            return (
                                <div className="mb-3" key={idx}>
                                    <label className="form-label">{ph.replace(/[<>]/g, '')}:</label>

                                    {hasExamples ? (
                                        <Select
                                            className="basic-single"
                                            classNamePrefix="select"
                                            isClearable
                                            isSearchable
                                            placeholder={'Select or type...'}
                                            options={exampleOptions}
                                            value={
                                                exampleOptions.find((opt) => opt.value === currentValue) ||
                                                (currentValue ? { label: currentValue, value: currentValue } : null)
                                            }
                                            onChange={(option) => handleInputChange(ph, option ? option.value : '')}
                                            onInputChange={(inputValue, { action }) => {
                                                if (action === 'input-change') {
                                                    handleInputChange(ph, inputValue);
                                                }
                                            }}
                                            theme={
                                                isDark ?
                                                    (theme) => ({
                                                        ...theme,                   colors: {
                                                            neutral0: '#151f2c',
                                                            primary: 'gray',
                                                            primary25: 'gray',
                                                        }
                                                    }) : (theme) => ({...theme})}
                                        />
                                    ) : (
                                        <input
                                            type="text"
                                            className="form-control"
                                            value={currentValue}
                                            onChange={(e) => handleInputChange(ph, e.target.value)}
                                            placeholder={selectedScenario.placeholdersText?.[ph] || ''}
                                        />
                                    )}
                                </div>
                            );
                        })}

                        <pre className={`p-3 rounded mb-3 border ${isDark ? 'bg-dark-subtle text-white border-secondary' : 'bg-light text-dark border'}`}>
                            {generateCommand()}
                        </pre>
                        <button className="btn btn-primary me-2" onClick={handleCopy}>
                            Copy Command
                        </button>
                    </div>
                </div>
            )}
        </div>
    );
};